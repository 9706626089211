import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import App from 'App'
import Alert from 'react-s-alert'
import { graphql, StaticQuery } from 'gatsby'
import * as Yup from 'yup'
import { Formik } from 'formik'
import ReCAPTCHA from 'react-google-recaptcha'
import { colors, fontSizes, space, radius, fontWeights, COLOR_CONSTANTS } from 'theme'
import { CONTAINER_MAX_WIDTH, ERROR_MESSAGE } from 'consts'
import { pxToRem } from 'helpers'
import SEO from 'components/SEO'
import { Flex, Grid, Box } from 'components/Layout'
import Container from 'components/Container'
import { H1, H2, Text } from 'components/Typography'
import Footer from 'components/Footer'
import LocalImage from 'components/LocalImage'
import ButtonWithLoading from 'components/ButtonWithLoading'
import BreadCrumbs from 'components/BreadCrumbs'
import TextArea from 'components/TextArea'
import imageHeader from 'static/images/social-media-tools/header_picture.webp'
import { blogContentAddingJoinTemplate } from './helper'

import GetStartedSection from './GetStartedSection'

const Wrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-x: hidden;
`

const StyledLink = styled(Text)`
  margin-bottom: ${space.s};
  padding-bottom: ${space.xs};
  text-decoration: none;
  color: ${colors.primary};
  &:hover {
    color: ${colors.primary};
  }
  cursor: pointer;
`

const ArticleBody = styled.div`
  max-width: 730px;
  color: #231f20;
  figure {
    margin: ${space.m} 0;
    padding: ${space.s} 0;
    text-align: center;
    border-radius: ${radius.l};
  }
  img {
    max-width: 100%;
    height: auto;
    border-radius: ${radius.l};
  }
  font-size: ${fontSizes.xl};
  a {
    color: ${colors.primary};
    font-weight: ${fontWeights.medium};
  }
  h2 {
    margin-top: ${space.xl};
    margin-bottom: ${space.m};
    padding: 0 0 ${space.s} 0;
    color: ${COLOR_CONSTANTS.DENIM};
  }
  h3 {
    margin-top: ${space.l};
    margin-bottom: ${space.m};
    padding: ${space.m} 0 ${space.s} 0;
  }
`

const StyledButtonWithLoading = styled(ButtonWithLoading)`
  box-shadow: 0px 18px 24px -8px rgba(36, 130, 253, 0.32);
`

const StyledCaptionWrapper = styled(Flex)`
  border-radius: ${radius.l};
  background: ${COLOR_CONSTANTS.WHITE};
  padding: ${pxToRem(24)};
`

const StyledRunningTextWrapper = styled(Flex)`
  flex-direction: column;
  border-radius: ${radius.l};
  border: 1px solid rgba(109, 124, 143, 0.3);
  background: rgba(109, 124, 143, 0.05);
  padding: ${space.m};
`

const { URL } = {
  URL: 'url',
}

const ValidationSchema = () => {
  const data = {
    [URL]: Yup.string().required(`Link is required`),
  }

  return Yup.object().shape(data)
}

const StyledTextWrapper = styled.div``

// TODO:: please create a new category, that would be not included on our site, I will filter it out in gatsby-node.js. Also we could take the title and description as meta information
const QRCodeGenerator = () => {
  const recaptchaRef = useRef()

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [result, setResult] = useState()

  const onRecaptchaExpired = () => {
    console.log('recaptcha expired')
    recaptchaRef.current.execute()
  }

  const download = () => {
    const a = document.createElement('a')
    a.href = result
    a.download = 'qr.png'
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  const handleSubmitForm = async (values) => {
    try {
      let recaptcha

      if (recaptchaRef && recaptchaRef.current) {
        recaptcha = recaptchaRef.current.getValue()

        if (!recaptcha) {
          await recaptchaRef.current.executeAsync()

          recaptcha = recaptchaRef.current.getValue()

          recaptchaRef.current.reset()
        }
      } else {
        recaptcha = 'disabled'
      }

      setIsSubmitting(true)

      const body = {
        url: values[URL] ? values[URL].trim() : undefined,
        recaptcha,
      }

      const res = await fetch(`${process.env.GATSBY_API_URL}/qrcode`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      })

      const response = await res.json()

      const { error, qr } = response || {}

      if (!response || error) {
        Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
      } else {
        setResult(qr)
      }

      // }
    } catch (error) {
      console.error(error)
      Alert.error(ERROR_MESSAGE, { timeout: 5000 })
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <StaticQuery
      query={graphql`
        {
          wordpressPost(slug: { eq: "internal-qr-code-generator" }) {
            content
          }
        }
      `}
      render={(data) => {
        const { wordpressPost } = data || {}
        const { content } = wordpressPost || {}

        const updatedContent = blogContentAddingJoinTemplate({ content })

        return (
          <App fullHeader>
            <SEO
              title="Free QR Code Generator - Create QR codes with ease - Vista Social"
              description="Reach new clients with a free QR code from Vista Social QR code generator."
            />

            <Wrapper>
              <Container
                pl={{ mobile: 'l', tablet: 'l' }}
                pr={{ mobile: 'l', tablet: 'l' }}
                position="relative"
                maxWidth={CONTAINER_MAX_WIDTH}
                width="100%"
                height="100%"
              >
                <Flex flexDirection="column" alignItems="center">
                  <Grid
                    mt="l"
                    mb="l"
                    pt={{ mobile: 0, desktop: 'm' }}
                    gridTemplateColumns={{ mobile: '1fr', desktop: '1fr 1fr' }}
                    gridGap="l"
                    width="100%"
                    alignItems="center"
                  >
                    <Flex width="100%" justifyContent="center" alignItems="center">
                      <Flex flexDirection="column">
                        <Flex width="100%" justifyContent="center" flexDirection="column">
                          <Flex
                            alignItems="center"
                            display={{ mobile: 'none', mobileLarge: 'flex' }}
                            justifyContent={{ mobile: 'center', desktop: 'flex-start' }}
                          >
                            <BreadCrumbs
                              data={[
                                { label: 'Vista Social', path: '/' },
                                { label: 'Free Tools', path: '/social-media-tools/' },
                                { label: 'QR Code generator', path: '/social-media-tools/qr-code-generator/' },
                              ]}
                            />
                          </Flex>
                          <Flex
                            mt="l"
                            flexDirection={{ mobile: 'row', desktop: 'column' }}
                            flexWrap="wrap"
                            justifyContent="center"
                          >
                            <H1
                              fontSize="4xl"
                              fontWeight="bold"
                              color={COLOR_CONSTANTS.DENIM}
                              textAlign={{ mobile: 'center', desktop: 'left' }}
                            >
                              <H1
                                as="span"
                                fontSize="4xl"
                                fontWeight="bold"
                                textAlign={{ mobile: 'center', desktop: 'left' }}
                              >
                                Free QR Code Generator
                              </H1>
                            </H1>
                          </Flex>
                          <H2
                            mt="m"
                            pt="s"
                            color="secondaryText"
                            fontSize="l"
                            textAlign={{ mobile: 'center', desktop: 'left' }}
                            fontWeight="normal"
                          >
                            Reach new clients with a free QR code from Vista Social's QR code generator. Ideal for
                            marketing materials and business cards, create well-designed QR codes in a few clicks.
                          </H2>
                        </Flex>
                      </Flex>
                    </Flex>
                    <Flex width="100%" ml="auto" mr={{ mobile: 'auto', desktop: 0 }} mt={{ mobile: 'l', desktop: 0 }}>
                      <LocalImage src={imageHeader} width="100%" mx="auto" maxWidth="617px" alt="QR Code generator" />
                    </Flex>
                  </Grid>
                </Flex>
              </Container>
            </Wrapper>

            <Wrapper bg={COLOR_CONSTANTS.SALT}>
              <Container
                pl={{ mobile: 'l', tablet: 'l' }}
                pr={{ mobile: 'l', tablet: 'l' }}
                py={{ mobile: 'l', tablet: 'xl' }}
                position="relative"
                maxWidth={CONTAINER_MAX_WIDTH}
                width="100%"
                height="100%"
              >
                <Flex maxWidth="920px" mx="auto" position="relative" flexDirection="column">
                  <Formik
                    initialValues={{
                      [URL]: '',
                    }}
                    onSubmit={handleSubmitForm}
                    validationSchema={ValidationSchema}
                  >
                    {({ values, handleChange, handleSubmit, touched, errors }) => (
                      <Box>
                        <Box>
                          <TextArea
                            label="Link"
                            placeholder="Enter your link"
                            values={values[URL]}
                            onChange={handleChange(URL)}
                            error={errors[URL] && touched[URL] && errors[URL]}
                            width="100%"
                            rows="1"
                          />
                        </Box>

                        <Flex mt="m" justifyContent="flex-end">
                          <StyledButtonWithLoading isLoading={isSubmitting} onClick={handleSubmit} type="submit">
                            <Text>{isSubmitting ? 'Generating' : 'Generate'}</Text>
                          </StyledButtonWithLoading>
                        </Flex>
                      </Box>
                    )}
                  </Formik>

                  {result && (
                    <StyledCaptionWrapper mt="xl" flexDirection="column" width="100%" position="relative">
                      <LocalImage
                        src={result}
                        width="100%"
                        mx="auto"
                        maxWidth="350px"
                        alt="QR Code generator"
                        download
                      />
                      <Box width="100%" textAlign="center">
                        <StyledLink onClick={download}>Download</StyledLink>
                      </Box>
                    </StyledCaptionWrapper>
                  )}
                </Flex>
              </Container>
            </Wrapper>

            {updatedContent && (
              <Wrapper bg={COLOR_CONSTANTS.WHITE}>
                <Container
                  pl={{ mobile: 'm', tablet: 'l' }}
                  pr={{ mobile: 'm', tablet: 'l' }}
                  position="relative"
                  maxWidth={CONTAINER_MAX_WIDTH}
                  width="100%"
                  height="100%"
                >
                  <Flex
                    mx="auto"
                    pl={{ mobile: 'l', tablet: 'l' }}
                    pr={{ mobile: 'l', tablet: 'l' }}
                    flexDirection="column"
                    alignItems="center"
                    position="relative"
                    maxWidth="1024px"
                    width="100%"
                  >
                    <ArticleBody className="post">
                      <StyledTextWrapper dangerouslySetInnerHTML={{ __html: updatedContent }} />
                    </ArticleBody>
                  </Flex>
                </Container>
              </Wrapper>
            )}

            <GetStartedSection />

            <Footer />

            <ReCAPTCHA
              sitekey="6Ld6gM0gAAAAAPwkW5CO806wVq_C1BanZ12XeZyI"
              size="invisible"
              ref={recaptchaRef}
              onExpired={onRecaptchaExpired}
            />
          </App>
        )
      }}
    />
  )
}

export default QRCodeGenerator
